// extracted by mini-css-extract-plugin
export var workSection = "WorkSection-module--workSection--UUDWd";
export var tag = "WorkSection-module--tag--7sYld";
export var activeTag = "WorkSection-module--activeTag--MGfQk";
export var blurb = "WorkSection-module--blurb--B+25M";
export var coverImageBackground = "WorkSection-module--coverImageBackground--jn4p9";
export var coverImage = "WorkSection-module--coverImage--2h8Yf";
export var protectedTag = "WorkSection-module--protectedTag--wCdoc";
export var detailsContainer = "WorkSection-module--detailsContainer--OXTNN";
export var icon = "WorkSection-module--icon--vcHdO";
export var details = "WorkSection-module--details--Lfrt-";